<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel'],
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            // 定义 组件 自身的确定函数
            handleOk() {
                this.ok()
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
        }
    }
</script>
<template>
    <div>
        <!--:visible="visible"      赋值 显示变量-->
        <!--@ok="handleOk"          赋值 确认函数-->
        <!--@cancel="handleCancel"  赋值 取消函数-->
        <a-modal title="" okText="确定" cancelText="取消" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <div>
                <div class="title">分析录入激活</div>
                <div class="content">
                    指派时间： 2021-11-10 14:43:42 <br>

                    指派人： zhangsan<br>

                    编号: zhangsan-20211110_2-化学需氧量-001<br>

                    名称: zhangsan-20211110_2-化学需氧量-001<br>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<style scoped>
    .title{ font-size: 20px; font-weight: bold}

    .content{ margin-top: 20px; line-height: 36px}

</style>
