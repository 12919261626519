<template>
    <div>
        <div>
            <table class="layui-table" style="width: 800px; background: #fff; margin: 0 auto;">
                <tbody>
                <tr>
                    <td>
                        <div class="fl">
                            <img src="http://api.tengbocn.com/upload/assets/new.png" style="vertical-align: middle; display: inline-block; width: 20px">
                            采集样品任务指派 <a style="margin-left: 20px; display: inline-block" @click="showModal">查看详情</a>
                        </div>
                        <div class="fr fc9">2021-12-25 12:23:12</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="fl">
                            <img src="http://api.tengbocn.com/upload/assets/new.png" style="vertical-align: middle; display: inline-block; width: 20px">
                            样品被拒收 <a style="margin-left: 20px" @click="showModal">查看详情</a></div>
                        <div class="fr fc9">2021-12-25 12:23:12</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="fl">
                            <img src="http://api.tengbocn.com/upload/assets/new.png" style="vertical-align: middle; display: inline-block; width: 20px">
                            样品被退回 <a style="margin-left: 20px" @click="showModal">查看详情</a></div>
                        <div class="fr fc9">2021-12-25 12:23:12</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="fl">
                            <img src="http://api.tengbocn.com/upload/assets/new.png" style="vertical-align: middle; display: inline-block; width: 20px">
                            指派采集任务 <a style="margin-left: 20px" @click="showModal">查看详情</a></div>
                        <div class="fr fc9">2021-12-25 12:23:12</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <newmodal :ok="modalClose" :cancel="modalClose" :visible="modal_visible"></newmodal>
    </div>
</template>
<script>
    import newmodal from './newmodal/index.vue';

    export default {
        components: {
            newmodal
        },
        data() {
            return {
                // 定义控制抽屉显示的变量
                modal_visible: false
            }
        },
        methods: {
            // 定义 关闭对话框时的 函数
            modalClose() {
                this.modal_visible = false
            },
            // 定义 打开对话框时的 函数
            showModal() {
                this.modal_visible = true
            }
        }

    }
</script>

<style scoped>
    .btner{ width: 100%; text-align: right}

    .ml10{ margin-left: 10px}

    .fl{ float: left}

    .fr{ float: right}

    .fc9{ color: #999999}

    td{ line-height: 40px}

</style>
